import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import Btn from "../components/btn"


const FourZeroFourPage = (props) => (
  <Layout location={props.location}>

  <Helmet>
    <title>Page not found</title>
  </Helmet>

  <section className="content">

    <div className="container">

      <PageTitle
        title="Page Not Found"
        subtitle="One of those 404 error pages"
      />

      <div className="content-main">
        <h3>Well... this is awkward.</h3>
        <p>We're sorry but we can't seem to find the page you're looking for :(<br />
        Please check that you entered the address correctly, go back to your previous page or head straight to the homepage...</p>
        <Btn to="/" value="Back to home" />
      </div>

    </div>

  </section>

  </Layout>
)

export default FourZeroFourPage
